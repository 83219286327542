.Stopwatch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Stopwatch-row {
  width: 100%;
}

.Stopwatch-stopwatch-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.Utils-section-container {
  display: flex;
  height: 50px;
  width: 100%;
}

.Utils-section-container-container {
  margin-top: 50px;
}

.Utils-section-container-countdown-length-input {
  width: 50px;
}

.DescribedMetric {
  background-color: #000000e3;
  backdrop-filter: blur(10px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.DescribedMetric input {
  padding: 0 7px;
  background-color: #00000000;
}


.DescribedMetric-value {
  color: #e1e1e1;
  font-size: 95%;
}

.DescribedMetric-label {
  color: #424242;
  font-size: 60%;
}

.SessionSectionButton {
  height: 100%;
  background: rgba(236, 236, 236, 20%);
  text-shadow: 0px 0px 3px rgba(32, 32, 32, 0.7);
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  transition-duration: 200ms;
  box-shadow: 1px -1px 5px rgba(0, 0, 0, 20%);
  font-size: 11px;
  backdrop-filter: blur(5px);

  text-shadow: none;
  background: rgba(20, 0, 45, 0.86);
  background: radial-gradient(circle, rgba(20, 0, 45, 0.86) 0%,rgba(17, 0, 38, 0.8) 100%);

  min-width: 50px;
}

.SessionSectionButton.SessionSectionButton-purple {
  background: rgba(20, 0, 45, 0.86);
  background: radial-gradient(circle, rgba(20, 0, 45, 0.86) 0%,rgba(17, 0, 38, 0.8) 100%);
}

.SessionSectionButton.SessionSectionButton-purple-pressed {
  background: rgba(20, 0, 45, 0.86);
  background: radial-gradient(circle, rgba(20, 0, 45, 0.86) 0%,rgba(17, 0, 38, 0.8) 100%);
  box-shadow: inset 0px 0px 5px #0707078a;
}

.SessionSectionButton.SessionSectionButton-purple-pressed:active {
  box-shadow: inset 0px 0px 15px #070707c2;
}

.SessionSectionButton.SessionSectionButton-white {
  background: rgba(255, 255, 255, 0.23);
  background: radial-gradient(circle, rgba(255, 255, 255, 0.23) 0%,rgba(222, 222, 222, 0.37) 100%);
}

.SessionSectionButton.SessionSectionButton-ice {
  background: rgba(45, 45, 45, 0.08);
  text-shadow: 0px 0px 3px #3e3e3e;
}

.SessionSectionButton.SessionSectionButton-ice:active {
  box-shadow: inset 0px 0px 5px #00000085;
}

.SessionSectionButton.SessionSectionButton-ice-pressed {
  background: rgba(45, 45, 45, 0.08);
  box-shadow: inset 0px 0px 15px #1a1a1a85;
}

.SessionSectionButton.SessionSectionButton-disabled {
  cursor: not-allowed;
  background: rgba(64, 64, 64, 0.29);
  box-shadow: inset 0px 0px 15px #00000085;
}

.SessionSectionButton.SessionSectionButton-disabled:active {
  box-shadow: inset 0px 0px 15px #00000085;
}

.IconButton-icon-row {
  color: #691add;
}

.IconButton-icon-row.IconButton-icon-row-white {
  color: #e4e4e4;
}

.IconButton-icon-row.IconButton-icon-row-purple {
  color: #9247ff;
}

.IconButton-icon-row.IconButton-icon-row-purple-pressed {
  color: #8000ff;
}

.IconButton-icon-row.IconButton-icon-row-ice {
  color: #f0f0f0;
}

.IconButton-icon-row.IconButton-icon-row-ice-pressed {
  color: #f0f0f0;
}

.IconButton-icon-row.IconButton-icon-row-disabled {
  color: #3c3c3c;
}

.IconButton-text-row {
  font-size: 60%;
  color: #aaa;
}

.IconButton-text-row.IconButton-text-row-white {
  color: #fff;
}

.IconButton-text-row.IconButton-text-row-purple {
  color: #9247ff;
}

.IconButton-text-row.IconButton-text-row-purple-pressed {
  color: #8000ff;
}

.IconButton-text-row.IconButton-text-row-ice {
  color: #f0f0f0;
}

.IconButton-text-row.IconButton-text-row-ice-pressed {
  color: #f0f0f0;
}

.IconButton-text-row.IconButton-text-row-disabled {
  color: #3c3c3c;
}

.Stopwatch-time-bar-spacer {
  background-color: #000000c9;
  flex: 1;
  min-width: 10px;
}

.Session-container {
  width: 100%;
}

.Session-container-main-row-input-container {
  flex: 1;
}

.Session-container-session-length {
  transition-duration: 400ms;
}

.Session-container-session-length-mute {
  color: #6a6a6a;
}

.Session-container-main-row-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0;
  border-radius: 0;
  font-size: 16px;
}

.Session-container-main-row-input:focus-visible {
  border: none;
}

.Session-container-main-row {
  display: flex;
  height: 48px;
}

.Session-container-notes-row {
  border-top: 5px solid #0000001f;
  height: 250px;
}

.Utils-section-countdown-time-left {
  transition-duration: 400ms;
}

.Utils-section-countdown-time-left.Utils-section-countdown-time-left-muted {
  color: #7b7b7b;
}
