.ProjectQuickMenu {
  position: absolute;
  background-color: #0f0f0f;
  box-shadow: -2px 2px 10px #090909;
  overflow: hidden;
}

.ProjectQuickMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ProjectQuickMenu button {
  width: 100%;
  background: none;
  box-shadow: none;
  text-align: left;
  height: 40px;
  font-size: 12px;
  border-radius: 0;
  margin: 0;
  border: none;
  padding: 0 10px;
  color: rgb(238, 238, 238);
  cursor: pointer;
}

.ProjectQuickMenu button:hover {
  background: var(--primary);
}

.ProjectQuickMenu-header {
  padding: 15px 10px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.ProjectQuickMenu-header-name {
  flex: 1;
}

.ProjectQuickMenu-icon {
  margin-right: 5px;
}
