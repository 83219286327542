.MultilineTextInput {
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  border-radius: 0;
  color: #ccc;
}

.MultilineTextInput-full-width {
  width: 100%;
}

.MultilineTextInput-full-height {
  height: 100%;
}

.MultilineTextInput-no-resizing {
  resize: none;
}
