.Modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(18, 18, 18, 90%);
}

.Modal-container {
  width: 100%;
  max-width: 530px;
  background-color: rgb(11, 11, 11);
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
}

.Modal-header-container {
  display: flex;
  background-color: rgb(0, 0, 0);
  color: white;
  word-break: break-all;
}

.Modal-header-headline-container {
  padding: 6px 40px;
  flex: 1;
}

.Modal-header-close-container {
  padding: 40px;
  height: 100%;
}

.Modal-header-close-container img {
  cursor: pointer;
  width: 30px;
}

.Modal-body {
  padding: 40px;
  max-height: 70vh;
  overflow-y: scroll;
}
