.ProjectLink-container {
  position: relative;
  width: 50px;
  height: 100%;
}

.ProjectLink-content-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex-shrink: 0;
  transition-duration: 300ms;
}

.ProjectLink-content-container-background-color {
  background-color: hsla(0, 0%, 4.3%, 0.4);
}

.ProjectLinkWrapper:nth-child(odd) .ProjectLink-content-container-background-color {
  background-color: rgba(0, 0, 0, 40%);
}

.ProjectLink-content-container-background-color:hover, .ProjectLinkWrapper:nth-child(odd):hover .ProjectLink-content-container-background-color {
  background-color: rgba(43, 43, 43, 0.76);
}

.ProjectLink-content-container.selected {
  outline: 1px solid var(--primary);
  outline-offset: -1px;
}

.ProjectLink-label {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  padding-top: 10px;
}

.ProjectLink-ColorPalette {
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  width: 100%;
}

.ProjectLink-bottom-bar {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 56%;
  background-color: rgb(34, 34, 34);
}

.ProjectLink-activity-score {
  color: rgb(54, 54, 54);
  width: 80%;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  padding-top: 4px;
  padding-bottom: 2px;
}

.ProjectLink-markers {
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectLink-target-marker {
  height: 4px;
  width: 4px;
  border-radius: 1px;
  background-color: var(--primary);
}

.ProjectLink-target-marker.high-vis {
  /*background-color: #bf0;*/
   background-color: #00ff4c;
  /* background-color: #00ff04; */

  /* background-color: #ff00d7; */
  /* background-color: #dfff00; */
  /* background-color: #00ffb7; */
  /* background-color: #f8ffaa; */
}

.ProjectLink-target-marker.low-vis {
  /* background-color: rgb(63, 66, 53); */
  background-color: rgb(54, 54, 54);
}

/*.ProjectLink-container.neglected .ProjectLink-activity-score {
  color: var(--primary);
}

.ProjectLink-container.due-soon .ProjectLink-activity-score {
  color: #e9d3ff;
}*/

.ProjectLink-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ProjectLink-background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ProjectLink-background-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProjectLink-background-image-container.ProjectLink-background-image-container-grayscale img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.ProjectLink-background-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ProjectLink-ProgressBar {
  /* bright version */
  background-color: rgba(173, 173, 173, 0.36);
  /* background-color: hsla(0, 0%, 26.7%, 0.71); */
  /* dark version */
  /* background-color: hsla(0, 0%, 16.1%, 0.71); */
  position: absolute;
  left: 0;
  bottom: 0;
  height: '100%';
  width: 7px;
  /* color: rgb(253, 255, 228); */
  /* bright version */
  color: rgb(188, 189, 168);
  /* color: #9fa08c; */
  /* dark version */
  /* color: #81826d; */
  overflow: hidden;
  font-size: 4px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* border-top: 1px solid #737373; */
}

.ProjectLink-ProgressBar span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
