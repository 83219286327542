@font-face {
  font-family: Oswald;
  font-weight: bold;
  src: url(./fonts/Oswald-Bold.ttf);
}

@font-face {
  font-family: Oswald;
  font-weight: 300;
  src: url(./fonts/Oswald-Light.ttf);
}

@font-face {
  font-family: Oswald;
  src: url(./fonts/Oswald-Regular.ttf);
}

:root {
  --primary: #7400ff;
  --primary-alpha: rgba(116, 0, 255, 0.84);
  --text-shadow: 0px 0px 3px rgba(0, 0, 0, 30%);
  --font-family: Oswald, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html {
  background-color: black;
}

body {
  margin: 0;
  background-color: black;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0, 0, 0, 0.45) 43%, rgba(0, 0, 0, 0.6) 100%);
  backdrop-filter: blur(2px);
  border: none;
  padding: 7px;
  font-family: var(--font-family);
  font-weight: 300;
  border-radius: 2px;
  box-shadow: 1px -1px 5px rgba(193, 193, 193, 0.02);
}

.input-out-of-sync {
  background: radial-gradient(circle, rgba(47, 47, 47, 0.45) 43%, rgba(28, 28, 28, 0.6) 100%);
}

input {
  color: rgb(209, 209, 209);
  font-size: 16px;
}

input.dark, textarea.dark {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.85) 43%, rgba(0, 0, 0, 0.9) 100%);
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

textarea {
  color: rgb(226, 226, 226);
  font-size: 16px;
}

textarea::placeholder, input::placeholder {
  color: rgb(109, 109, 109);
  text-shadow: 0px 0px 3px rgba(115, 115, 115, 0.3);
}
