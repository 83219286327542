.SignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SignIn-submit {
  margin-bottom: 10px;
}
