.SessionRow {
  display: flex;
  font-size: 12px;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  color: rgb(230, 230, 230);
}

.SessionRow-section-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.SessionRow-section-cta {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.SessionRow-date {
  white-space: nowrap;
  min-width: 100px;
}

.SessionRow-time {
  width: 35px;
}

.SessionRow-time-ended {
  color: rgb(183, 183, 183);
}

.SessionRow-time-started {
  color: rgb(255, 255, 255);
}

.SessionRow-duration {
  width: 50px;
  text-align: right;
  padding-right: 20px;
}

.SessionRow-project {
  max-width: 130px;
  text-align: center;
  padding-right: 20px;
}

.SessionRow-description {
  flex: 1;
  min-width: 200px;
  color: white;
}

.SessionRow-summary {
  word-break: break-word;
  font-size: 14px;
}

.SessionRow-notes {
  font-size: 11px;
  color: rgb(176, 176, 176);
  word-break: break-word;
  white-space: pre-line;
}

.SessionRow-description-input {
  width: 100%;
  padding: 0;
}

.SessionRow-notes-input {
  width: 100%;
  padding: 0;
  height: 100px;
}

.SessionRow-dates-form {
  padding-right: 5px;
}
