.ProjectLinkColorPalette-color-fill {
  flex: 1;
  width: 10px;
  min-height: 5px;
}

.ProjectLinkColorPalette-palette-container {
  border-radius: 1px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ProjectLinkColorPalette-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
