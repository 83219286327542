.Image {
  position: relative;
  width: 100%;
  height: 100%;
}

.BackdropWallpaper-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BackdropPictureWallpaper-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BackdropPictureWallpaper-background-blur {
  filter: blur(10px);
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.BackdropPictureWallpaper-background-blur img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BackdropPictureWallpaper-picture-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BackdropPictureWallpaper-picture-container img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.BackdropPictureWallpaper-picture {
  width: 90%;
  height: 90%;
}
