.ImageBrowser {
  max-width: 100%;
  height: 40px;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  background-color: #0000001f;
}

.ImageBrowser-inner {
  margin: 5px 4px;
  height: 40px;
  display: flex;
  gap: 8px;
  z-index: 2;
  position: relative;
}

.ImageBrowser-inner-blur {
  height: 45px;
  display: flex;
  position: absolute;
  top:0;
  left: 0;
  z-index: 1;
  filter: blur(10px);
}

.ImageBrowser-img-and-actions-container {
  position: relative;
  height: 100%;
  box-sizing: content-box;
  cursor: pointer;
  padding: 0;
  border: none;
  flex-shrink: 0;
  background-color: unset;
  display: flex;
}

.ImageBrowser-img-container {
  position: relative;
  height: 100%;
  box-sizing: content-box;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: unset;
}

.ImageBrowser-edit-button-container {
  position: relative;
  height: 100%;
  box-sizing: content-box;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: unset;
}

.ImageBrowser-img-container-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary);
  box-sizing: border-box;
}

.ImageBrowser-img-container-border2 {
  position: relative;
  border: 1px solid black;
  box-sizing: border-box;
  height: 100%;
}

.ImageBrowser-img-container-border3 {
  border: 1px solid var(--primary);
  box-sizing: border-box;
  height: 100%;
}

.ImageBrowser-img-container img {
  height: 100%;
  object-fit: contain;
}

.ImageBrowser-add-button {
  padding: 0 9px;
  display: flex;
  align-items: center;
  background-color: (45, 45, 45, 0.08);
  color: white;
  backdrop-filter: blur(5px);
}
