.SectionContainer {
  border: 5px solid #0000001f;
  box-sizing: border-box;
}

.SectionContainer-full-width {
  width: 100%;
}

.SectionContainer-full-height {
  height: 100%;
}

.SectionContainer-width-by-content {
  max-width: 100%;
  width: fit-content;
}
