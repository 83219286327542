.AddImageToProject h3 {
  margin: 0;
  margin-bottom: 15px;
}

.AddImageToProject-images-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.AddImageToProject-LinkImageButton {
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}

.AddImageToProject-LinkImageButton-image-container {
  height: 100%;
}

.AddImageToProject-LinkImageButton-image-container img {
  height: 100%;
  object-fit: contain;
}

.AddImageToProject-LinkImageButton-checkmark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(32, 32, 32, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #b1b1b1;
}

.AddImageToProject-LinkImageButton-checkmark-overlay:hover {
  color: #7b7b7b;
}

.AddImageToProject-upload-form-container {
  height: 50px;
  margin-bottom: 60px;
}
