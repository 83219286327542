.Header-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 50%);
  height: 131px;
  transition: height 1.5s ease-out, background-color 1.5s ease-out;
  background-image: url("striped_tile.png");
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(.3px);
  transition: opacity 1s ease-in-out;
}

.Header-muted {
  opacity: 0%;
  pointer-events: none;
}

.Header-container.full-screen {
  height: 100vh;
  background-color: rgba(0, 0, 0, 10%);
}

.Header-sub-container {
  flex: 1;
  display: flex;
  overflow-y: hidden;
}

.Header-logo-button-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.Header-logo-button-container.Header-logo-button-container-active {
  background-color: #0f0f0f;
}

.Menu-dropdown {
  position: absolute;
  top: 131px;
  left: 0;
  width: 100vw;
  max-width: 250px;
  background-color: #0f0f0f;
  box-shadow: -2px 2px 10px #090909;
  border-bottom-right-radius: 2px;
  overflow: hidden;
}

.Menu-dropdown button {
  width: 100%;
  background: none;
  box-shadow: none;
  text-align: left;
  height: 40px;
  font-size: 12px;
  border-radius: 0;
  margin: 0;
}

.Header-logo-container {
  flex: 1;
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.Header-logo {
  position: relative;
  bottom: 30px;
  flex-shrink: 0;
  flex: 1;
  transform: rotate(-90deg);
  margin-left: 1px;
}

.Header-logo-container img {
  width: 60px;
}

.Sticks-icon-container {
  font-size: 50px;
  display: flex;
  margin: 16px;
  justify-content: center;
}

.Sticks-icon-container img {
  width: 10px;
}

.Header-logo-beta-tag {
  background-color: rgb(255, 0, 102);
  color: black;
  position: absolute;
  right: -7px;
  bottom: -11px;
  padding: 1px 6px;
  font-size: 12px;
  border-radius: 3px;
}

.Header-content {
  flex: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.Header-ProgressBar {
  background-color: rgba(173, 173, 173, 0.36);
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 7px;
  overflow: hidden;
  font-size: 4px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.Header-ProgressBar-background {
  width: 100%;
  color: rgb(30, 30, 30);
}

.Header-ProgressBar-progress-bar {
  border-right: 1px solid rgb(179, 179, 179);
  color: rgb(60, 60, 60);
}
