.ProjectSelector-container {
  display: flex;
  height: 100%;
}

.ProjectSelector-show-inactive-projects-button {
  cursor: pointer;
  width: 23px;
  background-color: rgb(21, 21, 21);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.ProjectSelector-show-inactive-projects-button:hover {
  background-color: rgb(43, 43, 43);
}

.ProjectSelector-show-inactive-projects-button img {
  height: 16px;
}

.ProjectSelector-show-inactive-projects-button.active img {
  height: 16px;
  transform: rotate(180deg);
}
