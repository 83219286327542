.Button {
  background: rgba(236, 236, 236, 20%);
  color: rgb(236, 236, 236);
  text-shadow: 0px 0px 3px rgba(32, 32, 32, 0.7);
  border-radius: 2px;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  margin: 1px;
  transition-duration: 200ms;
  box-shadow: 1px -1px 5px rgba(0, 0, 0, 20%);
  font-size: 11px;
  font-family: -apple-system;
}

.Button:disabled {
  color: rgb(70, 70, 70);
  background: rgba(151, 151, 151, 0.2);
  cursor: not-allowed;
}

.Button:hover:enabled {
  background: var(--primary);
  color: rgb(238, 238, 238);
  text-shadow: none;
}

.Button.Button-dark {
  color: #f5f1fb;
  text-shadow: none;
  background: rgba(20, 0, 45, 0.86);
  background: radial-gradient(circle, rgba(20, 0, 45, 0.86) 0%,rgba(17, 0, 38, 0.8) 100%);
  padding: 7px 17px;
}

.Button.Button-grande {
  font-size: 16px;
  padding: 20px 35px;
  font-family: var(--font-family);
}

.Button.Button-dark:disabled {
  cursor: wait;
  background: rgba(15, 15, 15, 0.9);
  color: rgb(50, 50, 50);
  text-shadow: none;
  animation: buttonProcessing 1.7s ease-in infinite;
}

.Button.Button-dark:hover:enabled {
  background: var(--primary-alpha);
}

@keyframes buttonProcessing {
	0% {
    background: rgba(11, 11, 11, 0.9);
    color: rgb(70, 70, 70);
	}
	50% {
    background: rgba(0, 0, 0, 0.82);
    color: rgb(50, 50, 50);
	}
	100% {
    background: rgba(11, 11, 11, 0.9);
    color: rgb(70, 70, 70);
	}
}
