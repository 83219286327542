.FileInput-input {
  display: none;
}

.FileInput-label-disabled {
  pointer-events: none;
  color: rgb(70, 70, 70);
  background: rgba(151, 151, 151, 0.2);
  cursor: not-allowed;
}
