.Menu {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Menu-projects-selector-container {
  flex: 1;
  overflow-x: scroll;
}
