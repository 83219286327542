.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.validationErrorsContainer {
  color: #f01534;
  font-size: 12px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: 100;
}
