.Footer {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eee;
  padding: 10px;
}

.Footer-logo-container {
  width: 36px;
  padding-right: 4px;
}

.Footer-headline {
  display: flex;
}

.Footer-spacer {
  padding: 0 10px;
}
