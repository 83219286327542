.Link {
  color: var(--primary);
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font-family: var(--font-family);
}

.Link-on-black {
  background: #060606cf;
  border-radius: 1px;
  padding: 0 10px 0 10px;
}

.Link-sized {
  font-size: 14px;
}
