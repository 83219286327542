.Router {
  width: 100vw;
  height: 100vh;
  background-color: rgb(6, 6, 6);
  color: white;
  overflow-y: scroll;
}

.Router-wallpaper-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

.Flash-messages-container {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App-sync-status-label {
  animation: fadeIn cubic-bezier(.95,.05,.8,.04) 1s;
  font-size: 80%;
  background-color: rgba(66, 66, 66, 0.85);
  padding: 3px 12px;
  color: rgb(255, 255, 255);
}

.App-sync-status-label.danger {
  background-color: rgba(199, 255, 0, 0.85);
  color: rgb(11, 11, 11);
}

.App-sync-status-label.error {
  background-color: rgba(255, 0, 84, 0.85);
  color: rgb(0, 0, 0);
}

.App-flash-label {
  animation: fadeIn ease 0.5s;
  font-size: 80%;
  background-color: rgba(66, 66, 66, 0.85);
  padding: 3px 12px;
  color: rgb(255, 255, 255);
}

.App-flash-label.error {
  background-color: rgba(255, 0, 84, 0.85);
  color: rgb(0, 0, 0);
}
