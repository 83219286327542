.LandingPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LandingPage-content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 25px;
}

.LandingPage h1 {
  font-size: 55px;
}

.LandingPage-headline {
  font-size: 55px;
  word-break: break-word;
  text-align: center;
}

.LandingPage-header img {
  margin: 60px;
  width: 200px;
}

.LandingPage-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.LandingPage-actions > * {
  margin-bottom: 40px;
}

.LandingPage-or {
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}

.LandingPage-or-stripe {
  width: 1px;
  flex: 1;
  background-color: rgb(157, 157, 157);
  border: 1px solid #131313;
}

.LandingPage-or-word {
  padding-bottom: 5px;
}

.LandingPage-auth {
  width: 250px;
}
