.container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 1px;
  overflow: hidden;
  background-color: #0D0106;
}

.colorFill {
  width: 20px;
  height: 20px;
}
