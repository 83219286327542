.Backdrop {
  width: 100%;
  height: 100%;
}

.Backdrop-gradient-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.Backdrop-gradient-layer-block {
  background-color: #060606;
  width: 15%;
  height: 100%;
}

.Backdrop-gradient-layer-gradient {
  height: 100%;
  width: 40%;
  background: #060606;
  background: linear-gradient(90deg, rgba(6,6,6,1) 10%, rgba(0,0,0,0) 100%);
}

.Backdrop-dynamic-layer {
  position: absolute;
  top: 0;
  right: 0;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BackdropLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
