.App-content {
  margin-top: 131px;
  position: relative;
}

.App-3-cols {
  display: flex;
  gap: 40px;
  flex-wrap: wrap-reverse;
}

.App-3-cols-col-container {
  flex: 1;
  margin: 2%;
  overflow: hidden;
  min-width: 300px;
}

.App-3-cols-col-content {
  width: 100%;
  box-sizing: border-box;
}

.App-3-cols-col-content-stopwatch {
  display: flex;
  flex-direction: column;
}

.Dashboard-middle-panel-stopwatch {
  flex: 1;
  display: flex;
  align-items: center;
  transition: opacity .8s ease-in-out;
}

.Dashboard-middle-panel-stopwatch2 {
  margin: 50px 0;
  width: 100%;
}

.Dashboard-middle-panel-moodboard {
  display: flex;
  justify-content: center;
}

.Dashboard-panel-faded {
  opacity: 0%;
  pointer-events: none;
}

.Dashboard-todos-container {
  transition: opacity 1.2s ease-in-out;
}

.App-stopwatch-container {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.App-project-container {
  display: flex;
  justify-content: flex-end;
}

.Dashboard-project-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 70px;
  transition: opacity .4s ease-in-out;
}

.App-project-introduction {
  display: flex;
  flex-direction: row;
  text-align: right;
  max-width: 90%;
  word-wrap: break-word;
}

.App-project-introduction h1 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 50px;
  margin: 0;
  text-shadow: var(--text-shadow);
}

.App-project-introduction p {
  margin: 0;
  text-shadow: var(--text-shadow);
}

.App-project-introduction-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

.App-project-introduction-description {
  max-width: 400px;
  color: rgb(242, 242, 242);
}

.App-project-introduction-name {
  width: 100%;
}

.App-project-introduction-minutesPerDay {
  margin-top: 3px;
  font-size: 70%;
}

.App-project-notes {
  width: 100%;
  height: 450px;
}

.App-tail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin: 20px 0;
  transition: opacity .8s ease-in-out;
}

.App-sessions-row {
  width: 100%;
  max-width: 1000px;
}

.App-daily-activity-container {
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
