.WeeklyActivity {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.WeeklyActivity > * {
  font-size: 85%;
  padding: 0 20px;
  text-align: right;
  color: rgb(138, 138, 138);
}

.WeeklyActivity-week-number {
  color: rgb(204, 204, 204);
}
