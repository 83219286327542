.ImageEditor-image-row-container {
  display: flex;
  gap: 15px;
  background-color: #131313;
  border-radius: 5px;
  padding: 15px;
  flex-direction: column;
}

.ImageEditor-image-container {
  flex-shrink: 0;
  overflow: hidden;
  width: 100px;
  height: 80px;
  border: 1px solid #f2f2f2;
}

.ImageEditor-actions-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  flex-direction: column;
}
