.streakContainer {
  /*color: #e9d3ff;*/
  /*color: #e8f3ff;*/
  // color: var(--primary);
  // color: #b6cde6;
  // color: #c5b6e6;
  // color: #00ff4c;
  // color: #373737;
  color: #404040;
}

.streakMissed {
  color: #404040;
}

.streakMissedDarker {
  color: #2b2b2b;
}

.streakContainerRecordSetting {
  // color: #00ff4c;
  color: #aaa;
}

.noStreakSpacer {
  height: 2px;
}

.neglectedSinceDays {
  color: #ff2f42;
}
