.Todos {
  width: 100%;
}

.Todos ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Todo-container {
  margin-bottom: 5px;
  display: flex;
  gap: 10px;
}

.Todo-main {
  flex: 1;
  display: flex;
  gap: 7px;
}

.Todo-inputs-container {
  width: 100%;
}

.Todo-main input {
  width: 100%;
}

.Todo-main input[type='checkbox'] {
  margin: 0;
}

.Todo-main textarea {
  width: 100%;
}

.Todo-buttons {
  display: flex;
  flex-direction: column;
}

.TodoForm-description-input {
  width: 100%;
}

.Todo-notes-input {
  padding: 0;
  margin-bottom: 6px;
  color: #777;
  resize: vertical;
}

.Todo-description-input {
  padding: 0;
  margin-bottom: 3px;
}

.TodoForm-inline-form {
  display: flex;
}

.TodoForm-container {
  margin-bottom: 40px;
}

.Todos-todos-list {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Todos-show-completed-button-container {
  display: flex;
  justify-content: center;
}
